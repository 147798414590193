<template>
  <div class="m-4">
    <h1 class="typo-teaser-1">Hello Vol.at Community</h1>

    <ClientOnly>
      <div>
        You are loggedin: {{ isLoggedIn }}
        <p v-if="isLoggedIn">your username: {{ username }}</p>
      </div>
    </ClientOnly>

    <div class="flex flex-wrap gap-4">
      <NuxtLink class="p-2 bg-main-600" to="/themen/polizei">Themen Seite</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/suche">Suche</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/horoskop">Horoskop</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/verkehr">Verkehr</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/mondkalender">Mondkalender</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/newsletter">Newsletter</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/spritpreise">Spritpreise</NuxtLink>
      <NuxtLink class="p-2 bg-main-600" to="/notdienste">Notdienste</NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isLoggedIn, getUser } = useAuth()

const session = computed(() => getUser())
const username = computed(() => session.value?.username)
</script>
